import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BaseLayout from '../../components/layout/base-layout';
import BookList from '../../components/books/list';
import CatalogHeader from '../../components/common/catalog-header';
import PageColumn from '../../components/layout/page-column';
import Pagination from '../../components/common/pagination';
import RainbowBar from '../../components/common/rainbow-bar';
import Section from '../../components/layout/section';

export default (props) => {
  const start = (props.pageContext.page - 1) * props.pageContext.perPage;
  const publisherNode = props.data.publisher;
  const filteredBookNodes = props.data.publisher.data.Books
                                 .slice(start, start + props.pageContext.perPage);

  return (
    <BaseLayout>
      <Helmet>
        <title>Cookbooks Published by { publisherNode.data.Name }</title>
        <meta
          property="og:title"
          content={ `Cookbooks Published by { publisherNode.data.Name }` }
        />
      </Helmet>
      <CatalogHeader
        label={(
          <>
            Cookbooks Published by <span className="highlight">{ publisherNode.data.Name }</span>
          </>
        )}
      />
      <RainbowBar width={'thin'} />
      <Section topSpacing="section">
        <PageColumn>
          <BookList bookNodes={ filteredBookNodes } />
        </PageColumn>
      </Section>
      <Section topSpacing="section" bottomSpacing="section">
        <Pagination
          pathStem={`/publisher/${publisherNode.data.Slug}/`}
          page={props.pageContext.page}
          perPage={props.pageContext.perPage}
          totalPages={props.pageContext.totalPages}
        />
      </Section>
    </BaseLayout>
  );
};

export const query = graphql`
query($slug:String) {
  publisher:airtable(table:{eq:"Publishers"}, data: {Slug: {eq: $slug}}) {
    data {
      Slug
      Name
      Books {
        data {
          ...BookForListing
        }
      }
    }
  }
}
`;
